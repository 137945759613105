<template>
    <hr>
    <div class="py-5 row">
        <div class="fs-5 text-white py-4">
            <a href="" @click.prevent="iconClick" class="text-white">
                <i class="bi bi-twitter-x px-3"></i>{{ iconName1 }}</a>

            <a href="" @click.prevent="iconClick" class="text-white">
                <i class="bi bi-facebook px-3" @click="iconClick"></i>
                {{ iconName2 }}
            </a>
            <a href="" @click.prevent="iconClick" class="text-white">
                <i class="bi bi-youtube px-3" @click="iconClick"></i>
                {{ iconName3 }}
            </a>
            <a href="" @click.prevent="iconClick" class="text-white">
                <i class="bi bi-line px-3" @click="iconClick"></i>
                {{ iconName4 }}
            </a>


        </div>

        <hr class="text-white">
        <div class="px-4 py-2">
            <p class="text-white ">{{ msg }}</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            msg: 'Copyright © 2024承知株式会社. All rights reserved.',
            iconName1: 'X(Twitter)',
            iconName2: 'Facebook',
            iconName3: 'Youtuber',
            iconName4: 'Line'
        }
    },
    methods: {
        iconClick() {
            alert('comming soon');
        }
    }
}
</script>

<style scoped>
.router-link-active {
    text-decoration: none;
    color: black;
}

.routet-link {
    text-decoration: none;
    color: black;
}


a {
    text-decoration: none;
    color: black;
}
</style>