<template>
    <div class="row">
        <div class="card  text-white " style="border: 0cap;">
            <img src="@/assets/img/t3plus.png" alt="" class="card-img" width="100%" height="100%">
            <div class="card-img-overlay text-darkGreen parent-div">
                <div class="child-div">
                    <h1 class="card-title ">T3 Plus</h1>
                    <p>Scrub . Mop</p>
                    <div class="py-2">
                        <button class="btn btn-success">
                            {{ $t('homepage.btnMsg')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- d-flex justify-content-center align-items-center -->
  
<script>
export default {
    data() {
        return {
            msg: "your browser may be too old, that's why you can't see this video element",
        };
    },
};
</script>
  
<style scoped>
.custom-video {
    position: relative;
    width: 100%;
    object-fit: fill;
    filter: brightness(50%);
}

.parent-div {
    display: flex;
    align-items: center;
}

.child-div {
    margin-left: 60%;
    /* 或者使用其他适当的左边距 */
    /* 假设子元素的高度大于父元素的高度 */
}

.left-content {
    position: relative;
    left: 60%;
    top: 50%;
    transform: translateY(-50%);
    /* transform: translateX(-50%); */
}
</style>
  