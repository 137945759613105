export default {
    'navbar': {
        navBarBrand: '承知',
        navBarItem1: 'Homepage',
        navBarItem2: 'Product',
        navBarItem3: 'Company Info',
        navBarItem4: 'Contact',
        navBarItem5: 'Translation',
        navBarItem6: 'Technology',
        navBarItem7: 'Solution',
        navBarItem8: 'Service',
    },
    'homepage': {
        btnMsg: 'Click to details',
        title: 'Technology driven',
        subTitle: 'Ultimate calculation example/millimeter-level positioning/billion-level data processing',
        content1: 'More reliable operation: powerful chip support, ultimate computing power optimization ',
        content2: 'Adapt smarter: dynamic prediction and obstacle avoidance, flexible speed control',
        content3: 'Inspection-style cleaning: 3D panoramic coverage, AI algorithm blessing',
        content4: 'Digital operation and maintenance: efficient cloud management and customized access support',
        btn: 'learn more',
    },
    'product': {
        ProductDetailMsg1: 'Cleaning area up to 1200 square meters per hour',
        ProductDetailMsg2: 'Charging station available in two types: charging only or charging, water refilling and draining',
        ProductDetailMsg3: 'Supports quick battery replacement in 30 seconds',
        ProductDetailMsg4: '3D LiDaR, 2D LiDaR, depth camera, ultrasonic sensor',
        ProductDetailMsg5: '50L fresh water and 45L dirty water tanks support long cleaning times',
        ProductDetailMsg6: 'Spreader as part of the robot body, internal structure, flexible modification and addition possible',
        ProductTitle1: '600~1200㎡/h',
        ProductTitle2: 'charging station',
        ProductTitle3: 'quick battery replacement',
        ProductTitle4: 'multiple centers',
        ProductTitle5: 'large capacity water tank',
        ProductTitle6: 'Built-in disinfectant sprayer',
        ProductIcon1: 'bi-speedometer',
        ProductIcon2: 'bi-fuel-pump-fill',
        ProductIcon3: 'bi-hand-thumbs-up',
        ProductIcon4: 'bi-webcam',
        ProductIcon5: 'bi-safe',
        ProductIcon6: 'bi-ubuntu',
    }
}