export default {
    'navbar': {
        navBarBrand: '承知',
        navBarItem1: 'ホームページ',
        navBarItem2: '製品情報',
        navBarItem3: '会社情報',
        navBarItem4: '問い合わせ',
        navBarItem5: '翻訳',
        navBarItem6: '技術提供',
        navBarItem7: '解決応用',
        navBarItem8: 'サービス',
    },
    'homepage': {
        btnMsg: '詳しく見る',
        title: 'テクノロジードライバー',
        subTitle: '究極の計算例／ミリ単位の測位／十億レベルのデータ処理',
        content1: 'より信頼性の高い動作: 強力なチップのサポート、究極のコンピューティング能力の最適化 ',
        content2: 'よりスマートに適応: 動的予測と障害物回避、柔軟な速度制御',
        content3: '検査スタイルのクリーニング: 3D パノラマ範囲、AI アルゴリズムの祝福',
        content4: 'デジタル運用と保守: 効率的なクラウド管理とカスタマイズされたアクセスのサポート',
        btn: '詳しく知る',
    },
    'product': {
        ProductDetailMsg1: '一時間あたりの掃除範囲は最大1200平方メートル',
        ProductDetailMsg2: '充電のみまたは充電、水の補充と排出、二つのタイプから選択可能な充電ステーション',
        ProductDetailMsg3: '30秒でのクイックバッテリー交換をサポート',
        ProductDetailMsg4: '3D LiDaR、2D LiDaR、深度カメラ、超音波センサー',
        ProductDetailMsg5: '50L清水と45L汚水のタンクで長時間の掃除をサポート',
        ProductDetailMsg6: 'ロボットの本体の一部としてのスプレッター、内臓構造、柔軟な変更や追加可能',
        ProductTitle1: '600~1200㎡/h',
        ProductTitle2: '充電ステーション',
        ProductTitle3: 'クイックバッテリー交換',
        ProductTitle4: '複数のセンター',
        ProductTitle5: '大容量の水タンク',
        ProductTitle6: '内臓式消毒スプレッター',
        ProductIcon1: 'bi-speedometer',
        ProductIcon2: 'bi-fuel-pump-fill',
        ProductIcon3: 'bi-hand-thumbs-up',
        ProductIcon4: 'bi-webcam',
        ProductIcon5: 'bi-safe',
        ProductIcon6: 'bi-ubuntu',
    }
}