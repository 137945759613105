<template>
    <div class="row">
        <div class="card bg-dark text-white">
            <video autoplay="true" muted loop class="custom-video">
                <source src="@/assets/homepage.mp4" type="video/mp4">
                {{ msg }}
            </video>
            <div class="card-img-overlay d-flex justify-content-center align-items-center">
                <div>
                    <h1 class="card-title">Let technology change your life</h1>
                    <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional
                        content. This content is a little bit longer.</p>
                    <p class="card-text">Last updated 3 mins ago</p> -->
                    <div class="py-4">
                        <button class="btn btn-success">
                            {{ $t('homepage.btnMsg')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            msg: "your browser may be too old, that's why you can't see this video element",
        };
    },
};
</script>
  
<style scoped>
.custom-video {
    position: relative;
    width: 100%;
    object-fit: fill;
    filter: brightness(50%);
}

.card-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}
</style>
  