<template>
    <nav class="navbar  bg-body-tertiary  navbar-expand-lg fixed-top">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">Shou-Chi</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarText">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/"><a class="nav-link" aria-current="page" href="#">{{ $t('navbar.navBarItem1')
                        }}</a></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/product"> <a class="nav-link" href="#">{{ $t('navbar.navBarItem2')
                        }}</a></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/tech"> <a class="nav-link" href="#">{{ $t('navbar.navBarItem6')
                        }}</a></router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/solutions"> <a class="nav-link" href="#">{{ $t('navbar.navBarItem7')
                        }}</a></router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link to="/company"><a class="nav-link" href="#">{{ $t('navbar.navBarItem8')
                        }}</a></router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link to="/company">
                            <a class="nav-link" href="#">{{ $t('navbar.navBarItem4') }}</a>
                        </router-link>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item dropdown px-4">
                        <a class="nav-link " href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <i class="bi bi-translate fs-3"></i>
                            Transl.
                        </a>
                        <ul class="dropdown-menu fs-5" aria-labelledby="navbarDropdownMenuLink">
                            <li class="mt-2"><a class="dropdown-item" href="#" @click="changeLang('ja')">{{ navBarMenu1
                            }}</a>
                            </li>
                            <li class="my-2"><a class="dropdown-item" href="#" @click="changeLang('en')">{{ navBarMenu2
                            }}</a>
                            </li>
                            <li class="my-2"><a class="dropdown-item" href="#" @click="changeLang('zh')">{{ navBarMenu3
                            }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import i18n from '@/lang/index'
export default {

    data() {
        return {
            navBarMenu1: '日本語',
            navBarMenu2: 'English',
            navBarMenu3: '简体中文',
        }
    },
    methods: {
        changeLang(lang) {
            i18n.global.locale = lang;
        }
    }
}
</script>
<style scoped>
.router-link-active {
    text-decoration: none;
    color: black;
}

.routet-link {
    text-decoration: none;
    color: black;
}


a {
    text-decoration: none;
    color: black;
}
</style>