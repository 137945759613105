<template>
  <div class="home">
    <HomePageVedio />
    <div class="row bg-lightGreen py-5 text-center">

    </div>
    <div class="row bg-light mx-4 py-5">
      <div class="col-lg-6 col-sm-12 px-4">
        <HomeProductCard></HomeProductCard>
      </div>
      <div class="col-lg-6 col-sm-12 px-4">
        <HomeProductCard2></HomeProductCard2>
      </div>
    </div>
    <div class="row py-5">
      <HomeTech>

      </HomeTech>
    </div>
    <!-- <Products /> -->

  </div>
</template>

<script>
// @ is an alias to /src
import HomePageVedio from '@/components/video/HomePageVideo.vue'
import Products from '@/components/Products.vue'
import HomeProductCard from "@/components/home/HomeDetails.vue";
import HomeProductCard2 from "@/components/home/HomeDetails2.vue";
import HomeTech from '@/components/home/HomeTech.vue';


export default {
  name: 'HomeView',
  components: {
    HomePageVedio,
    Products,
    HomeProductCard,
    HomeProductCard2,
    HomeTech
  }
}
</script>
