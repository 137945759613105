<template>
    <div class="row">
        <div class="card  text-white " style="border: 0cap;">
            <img src="@/assets/img/homeTech.png" alt="" class="card-img" width="100%" height="100%">
            <div class="card-img-overlay text-darkGreen parent-div">
                <div class="child-div text-white">
                    <h1 class="card-title "> {{ $t('homepage.title')
                    }}</h1>
                    <p>{{ $t('homepage.subTitle')
                    }}</p>
                    <div class="my-4">
                        <p>{{ $t('homepage.content1') }}</p>
                        <p>{{ $t('homepage.content2') }}</p>
                        <p>{{ $t('homepage.content3') }}</p>
                        <p>{{ $t('homepage.content4') }}</p>

                    </div>
                    <div class="py-2">
                        <button class="btn btn-light text-darkgreen btn-lg">
                            {{ $t('homepage.btnMsg')
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- d-flex justify-content-center align-items-center -->
  
<script>
export default {
    data() {
        return {
            title: '科技驱动',
            subTitle: '极致算例/毫米级定位/亿级数据处理',
            content1: '运行更可靠：强大芯片支撑、极致算力优化 ',
            content2: '适应更聪明：动态预判避障、灵活速度控制',
            content3: '巡检式清洁：3D全景覆盖、AI算法加持',
            content4: '数字化运维：云端高效管理、支持定制接入',
            btn: '了解更多',
        };
    },
};
</script>
  
<style scoped>
.parent-div {
    display: flex;
    align-items: center;
}

.child-div {
    margin-left: 10%;
    /* 或者使用其他适当的左边距 */
    /* 假设子元素的高度大于父元素的高度 */
}

.left-content {
    position: relative;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    /* transform: translateX(-50%); */
}
</style>
  