export default {
    'navbar': {
        navBarBrand: '承知',
        navBarItem1: '主页内容',
        navBarItem2: '产品信息',
        navBarItem3: '公司信息',
        navBarItem4: '联系我们',
        navBarItem5: '翻译',
        navBarItem6: '产品技术',
        navBarItem7: '解决方案',
        navBarItem8: '售后服务',
    },
    'homepage': {
        btnMsg: '查看详情',
        title: '科技驱动',
        subTitle: '极致算例/毫米级定位/亿级数据处理',
        content1: '运行更可靠：强大芯片支撑、极致算力优化 ',
        content2: '适应更聪明：动态预判避障、灵活速度控制',
        content3: '巡检式清洁：3D全景覆盖、AI算法加持',
        content4: '数字化运维：云端高效管理、支持定制接入',
        btn: '了解更多',
    }
}