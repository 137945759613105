<template >
  <header class="">
    <NavbarContent />
  </header>
  <main class="bg-lightGreen">
    <router-view />
  </main>
  <footer class="bg-mediumGreen">
    <FooterContent />
  </footer>
</template>
<script>
import FooterContent from '@/components/Footer.vue'
import NavbarContent from '@/components/Navbar.vue'
export default {
  components: {
    FooterContent,
    NavbarContent
  }
}
</script>
<style>
/* .router-link {
  color: black;
} */
main {
  min-height: 992px;
  padding-top: 72px; /* Adjust the value based on your navbar's height */
}

.bg-lightGreen {
  background-color: #EAF2E6;
}

.bg-mediumGreen {
  background-color: #518A70;
}

.bg-darkGreen {
  background-color: #3C4453;
}

.text-lightGreen {
  color: #EAF2E6;
}

.text-mediumGreen {
  color: #518A70;
}

.text-darkGreen {
  color: #3C4453
}
</style>
