<template>
    <div class="row py-5 px-4">
        <div class="col-lg-8">
            <img src="@/assets/img/cleaningRobot2.jpg" alt="" class="cardImg">
        </div>
        <div class="col-lg-4 d-flex justify-content-center align-items-center">
            <router-link to="/product">
                <button class="btn btn-primary btn-lg px-5 fs-3 border shadow"><a href=""></a>{{ $t('homepage.btnMsg') }}</button>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            btnMsg: '詳しく見る',
            cardDefaultTitileMsg: '',
            cardDefaultBodyMsg: '他の商品に今にホームページ更新しない'
        }
    }
}
</script>
<style>
.cardImg {
    width: 100%;
}

.custom-card {
    height: 418px;
}
</style>